export const projects = [
    {
      id: '0',
      title: 'fifteen app NY',
      category: 'Category 1',
      header: '/fifteen.webp', // or video path
      projectImage: '/fifteen.webp', // Add the corresponding background image
      images: [
        { src: '/gif3.gif', description: <>Fifteen<br /><br />  Fifteen app serves as a unique platform that facilitates connections between individuals across various industries via video calls. Whether you're looking for assistance or merely wish to explore experts from diverse fields, the app streamlines the process, allowing users to find their ideal expert by browsing profiles, reading descriptions, comparing rates, and evaluating previous reviews. <br /><br /> Scheduling is made easy, with the option to arrange an immediate meeting or set one for a future date. Additionally, Fifteen offers partnership opportunities, enabling individuals to monetize their expertise and earn income from calls, regardless of their geographical location.</> },
        { src: '/fif.webp', description: <>Challenge<br /><br /> During the unsettling times of the COVID-19 pandemic, the initial concept of the Fifteen app was confined to connecting celebrities through video calls. <br /><br />The main challenge was convincing the client to see beyond this and recognize the potential to connect professionals from various industries to customers. This vision would enable customers to access expert services from the comfort of home while allowing professionals to continue offering services and generating income during an economically challenging period.</> },
        { src: '/GIF2.gif', description: <>My role<br /><br /> My responsibilities were comprehensive, starting from landing the client's brief to creating the complete design. I developed a design system in Figma, crafted data models, prototyped complete flow interactions, and made key marketing decisions. <br /><br /> Managing the collaboration between the team in NY and the development team in Mexico was another significant aspect of my role. Quality assurance (QA) to ensure the app's functionality and appeal was also under my purview.</> },
        { src: '/fif4.gif', description: <>Research<br /><br /> The research phase encompassed both qualitative and quantitative methods. Qualitative research was vital in the early stages to shape the product's features and user flows. <br /><br />Once we reached a beta version, quantitative research guided us in simplifying the onboarding process. This research enabled us to create an app that allowed anyone to have any professional advice from anywhere in the world a few taps away.</> },
        { src: '/23.webp', description: <>Target pain points:<br /><br /> A critical concern was developing a simple yet global payment dispersing platform suitable for the startup's reduced staff team. <br /><br />By integrating the Stripe Connect platform, we created an easy process for professionals to sign up, verify, and begin offering their services. At each week's end, earnings were totaled, and funds sent through Stripe Connect to their associated accounts. <br /><br />This approach provided a streamlined, tax-efficient solution that supported the business's unique operational needs.</> },
        { src: '/4.webp', description: <>Product Ideas<br /><br />The Fifteen app is akin to a fusion between Yellow Pages and Uber, functioning as an on-demand service. As an open platform, it empowers individuals to work from anywhere, either as a supplementary income source or a means to globalize their business without abandoning their professional activity.</> },
        { src: '/fifteenp1.webp', description: <>Validation / Prototyping:<br /><br /> Prototyping was an extensive process, with Figma used to create multiple versions for various types of testing. <br /><br />Guerrilla testing was conducted many times at a Brooklyn bar owned by a Fifteen partner. These real-world environments enabled us to prototype and refine tasks and functionalities on the fly, iterating and improving the design based on immediate feedback.</> },
        { src: '/15.gif', description: <>Final Design<br /><br />The final design represents an open platform that allows anyone to work from anywhere in the world, generate extra income, or globalize their business without leaving their professional activity. <br /><br />With innovative features and a design built for accessibility, the app epitomizes a new era of professional connectivity.</> },
        { src: '/4-1.webp', description: <>Results & Learnings<br /><br /> The Fifteen app project taught me the art of persuading a client to rethink their initial concept. The journey also involved understanding and navigating the legal and procedural intricacies of establishing an LLC in NY for this project. <br /><br /> Fifteen stands as a testament to creative vision, collaborative teamwork, and a keen understanding of market needs and opportunities during a challenging global period.</> },
      ]
      
    },
    {
      id: '1',
      title: 'dam health UK',
      category: 'Category 1',
      header: '/dam.webp', // or video path
      projectImage: '/dam.webp', // Add the corresponding background image
      images: [
        { src: '/dammmm.webp', description: <>DAM Platform<br /><br /> DAM Health UK, a company specializing in clinical studies and health products, experienced significant growth during the COVID-19 pandemic, leading to an expansion outside the UK. I played a key role in directing the technological operations for DAM's entry into the Latin American market. <br /><br />My responsibilities included building and overseeing a team for the development and delivery of new products tailored for this region. Our team's success culminated in a platform that included mobile apps for customers and internal use, a web application, and daily administrative tools. <br /><br />This successful venture led to my continued collaboration with DAM Health UK, further contributing to product development within the United Kingdom.</> },
        { src: '/dambien.gif', description: <>Challenge<br /><br /> The project's challenges were multifaceted and required a unique approach.<br /><br />The Latin American market's stark contrast to the UK's presented a complex issue. Understanding how to offer DAM's product and services to this new market required careful analysis and adaptation.<br /><br />Coordinating over 10 different tech teams for integration with associated laboratories performing clinical studies was a massive undertaking.<br /><br /> The need to stand out from the competition through innovative services added another layer of complexity. <br /><br />Furthermore, directing the new Latin American platform development involved collaboration with India's past platform development team and the UK IT team, ensuring seamless cooperation between the continents.</> },
        { src: '/damyo.gif', description: <>My role<br /><br /> I wore many hats during this project. Besides building, establishing, and overseeing a multinational team of developers across the UK, India, and Mexico, I was charged with orchestrating their collaborative work.<br /><br /> My role expanded into the realms of complete product design, including the creation of new services never before offered by DAM.<br /><br /> The process involved developing a design system for the entire platform and executing additional managerial tasks such as organizing work trips and constant meetings. My leadership ensured a seamless flow of ideas and implementation across the continents.</> },
        { src: '/1644.webp', description: <>Research<br /><br />The research phase was exhaustive and robust, involving user interviews with various stakeholders in the service process. <br /><br />Usability testing was conducted for each proposed interface, while case studies for internal users and A/B testing for final proposal selection enriched our understanding of user needs. <br /><br />This meticulous approach laid the groundwork for a product precisely tuned to its target audience.</> },
        { src: '/bookingj.gif', description: <>Target pain points:<br /><br /> The Latin American market's purchasing decision was a focal point, as economic disparities compared to the European market made selling products and services more challenging. The need to align product offerings with local financial realities required careful analysis and innovation.</> },
        { src: '/dam1.gif', description: <>Product Ideas<br /><br />Two groundbreaking product ideas emerged:<br /><br />1. Door-to-Door Service: Tailored for tourists unfamiliar with sprawling cities like Mexico City, this service started with COVID tests and expanded into sexual health clinical studies. Real-time staff tracking enhanced the customer experience, making it an attractive solution for locals as well.<br /><br />2. Medical Consultations with In-Call Actions: A pioneering online consultation feature allowed doctors to prescribe DAM's products or services within a video call. This in-call option increased the likelihood of purchase and streamlined the booking process with unique codes.</> },
        { src: '/damfinal.gif', description: <>Final Design<br /><br />1. Progressive Web App: Built in Typscript.js, it featured a booking engine for customers to find the nearest lab, including Google Maps API integration and Stripe for payment processing.<br /><br />2. Mobile Apps: Developed in Flutter, this iOS and Android app offered tracking of DAM's staff for door-to-door services, online booking, and medical consultations through video calls. The integrated medical profile and studies history further enriched user experience.<br /><br />3. Admin Dashboard: Developed in Typescript.js, this internal platform provided oversight of the entire operation, including sales, product, and location management.<br /><br />4. Hosting on AWS Kubernetes: Ensuring fast multi-region deployment, AWS Kubernetes was the backbone for this innovative ecosystem.</> },
        { src: '/aaa.webp', description: <>Results & Learnings<br /><br /> The project's success was marked by overcoming complex challenges, innovating solutions, and leading a multinational team to new horizons.<br /><br /> By understanding the unique Latin American market and deploying tailored products and services, DAM established itself as a trailblazer. The collaboration and leadership skills I demonstrated during this project prepared me for future challenges, and the insights gathered are invaluable assets that will continue to guide my career.</> },
      ]
      
    },
    {
      id: '2',
      title: 'ray-ban international',
      category: 'Category 1',
      header: '/rayban.webp', // or video path
      projectImage: '/rayban.webp', // Add the corresponding background image
      images: [
        { src: '/rb1.webp', description: <>Challenge<br /><br /> The primary challenge was coordinating a team to work with major corporations such as Luxottica and Ray-Ban. <br /><br />This required delivering an online game for an international digital Ray-Ban campaign within a very tight timeframe of just four weeks.<br /><br /> Another challenge was finding a universally recognizable and engaging game that could be quickly implemented but still be fun to play. The idea needed to resonate across cultures and different continents.</> },
        { src: '/agile2.gif', description: <>My role<br /><br /> As the overseer of product planning and UI/UX design, I was responsible for recruiting and forming a development team. <br /><br />My duties included daily coordination with Ray-Ban's main offices in Italy and designing and maintaining an AGILE approach to manage the project's fast-paced timeline. The project initially aimed for the Mexico website but was adopted globally by the Milan marketing team, requiring careful management and collaboration.</> },
        { src: '/rb3.webp', description: <>Research<br /><br /> Desk research focused on identifying a universally recognized game that could be adapted quickly but remain engaging. <br /><br />The selection of the classical snake game was based on its worldwide familiarity and ease of implementation, ensuring a connection with Ray-Ban's global audience.</> },
        { src: '/rb6.webp', description: <>Product Ideas<br /><br />The final product was a revisited version of the classical snake game, designed to reveal the four iconic Ray-Ban shades shapes as the snake ate the dots.<br /><br /> This idea leveraged the universal appeal of a familiar game, making it suitable for rapid development and deployment.</> },
        { src: '/rbj.gif', description: <>Final Design<br /><br />The final design consisted of a reinvented snake game that incorporated Ray-Ban's iconic shades into the gameplay. This design was translated into over 15 languages and implemented quickly to meet the demanding schedule.</> },
        { src: '/rb2.webp', description: <>Results & Learnings<br /><br /> The project was successfully completed and launched online on Ray-Ban's global site within three weeks. <br /><br />The ability to work in an AGILE fashion, coordinate across different regions, and align with the needs of a large corporation such as Luxottica led to a project that not only met the intended goal for the Mexico website but was adopted globally.<br /><br /> The experience reinforced the importance of flexibility, rapid decision-making, and effective cross-cultural communication.</> },
      ]      
    },
    {
      id: '3',
      title: 'vet on call',
      category: 'Category 1',
      header: '/vetoncall.webp', // or video path
      projectImage: '/vetoncall.webp', // Add the corresponding background image
      images: [
        { src: '/aaaa.webp', description: <>Vet on Call is an on-demand veterinary services platform that consists of two mobile apps, an admin dashboard, and a landing page. This platform provides remote consultations via scheduled and instant video calls and chat. Additionally, it facilitates on-site consultation pre-booking, offers an emergency guide, and maintains a record of each pet's health history. It's an open platform for veterinarians and care centers to join, enabling them to expand their customer base and increase revenue.<br /><br /> The main challenges of the Vet On Call project included developing a comprehensive solution that could cater to both horse owners and their grooms or stable boys. Horses' unique needs and size posed a particular challenge, along with the need to provide proper advising to animal owners.<br /><br /> The solution had to be broad enough to encompass small species like cats and dogs, yet specialized enough to serve the specific needs of large animals such as horses.<br /><br /> A system was needed that would connect multiple caregivers to a single pet and provide appropriate and timely veterinary assistance.</> },
        { src: '/vetfigma.webp', description: <>My role<br /><br /> As the product designer, UI/UX designer, project manager, and web developer for Vet on Call, my responsibilities were diverse. <br /><br />I guided the entire design process, coordinated the project, and even took on web development tasks. My role required a thorough understanding of the unique challenges associated with veterinary care for a variety of species and the need to create a flexible yet intuitive platform that could serve both individual pet owners and professional caregivers like grooms.</> },
        { src: '/vetgif2.gif', description: <>Research<br /><br /> Research for Vet On Call was comprehensive, including user interviews with pet owners, focus groups with veterinarians specializing in different species, and usability testing once the beta version was developed. <br /><br />Understanding the varying needs and preferences of pet owners and caregivers, as well as the specific requirements of different animal species, was crucial to shaping the product's design.</> },
        { src: '/group7.webp', description: <>Target pain points:<br /><br /> The key pain points addressed by Vet On Call included the challenges of caring for large species like horses, dealing with species transportation, finding suitable care centers, and ensuring correct advising. <br /><br />The app had to provide comprehensive solutions for these complex issues, tailored to the needs of different animals and their caregivers.</> },
        { src: '/provet.gif', description: <>Product Ideas<br /><br />The app's features were designed to address these pain points, including:<br /><br /> - Emergency Guidance: A gallery to guide customers on distinguishing emergencies.<br /><br />- Chat Consultation: Allowing interaction with vets through text, photos, and videos.<br /><br />- Videocall Consultation: Immediate or scheduled connections with specialized vets.<br /><br />- Care Center Search: Finding centers based on location, equipment, and services.<br /><br />- Important Notifications: Alerting customers to vital information like sanitary outbreaks.<br /><br />- Unique QR Code for Each Animal: Linking multiple users to a pet for joint care and purchases.</> },
        { src: '/vetf.gif', description: <>Final Design<br /><br />The final design of Vet On Call was a comprehensive platform that not only offered videocall consultations, asynchronous chat consultations, and in-site consultation bookings but also allowed vets and care centers additional income and exposure to new clients and markets. <br /><br />It's an all-in-one solution tailored to the unique needs of various animal species and their caregivers.</> },
        { src: '/vetoncall.png', description: <>Results & Learnings<br /><br /> Working on Vet On Call provided unique experiences, such as interacting with users in fields caring for horses. <br /><br />Taking a chance at coding the landing page website, including a form for vets and care centers to join the platform, was a rewarding challenge. <br /><br />The project demonstrates the importance of deep user understanding, flexible design thinking, and the willingness to embrace diverse roles in product development. <br /><br />It was a testament to the power of empathy, innovation, and collaboration in creating a product that truly resonates with its users.</> },
      ]
      
    },
    {
      id: '4',
      title: 'portfolio - react',
      category: 'Category 1',
      header: '/portfolio.webp',
      projectImage: '/portfolio.webp',
      images: [
        { 
          src: '/dddd.webp', 
          description: (
            <>
              Read my article in medium about how I'm embracing AI through Chat-GPT to expand my capabilities as Product Manager & Designer:<br /><br /> 
              <a class="medium" href="https://medium.com/@lopezb.jl/embracing-ai-through-chat-gpt-to-expand-my-capabilities-as-a-product-designer-bcde0f69c352" target="_blank" rel="noopener noreferrer">Read here.</a>
            </>
          ) 
        },
      ],
    }
    
  ];
  